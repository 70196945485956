<template>
	<div
		class="c-block-list-shortcuts-custom"
		:class="{
			'c-block-list-shortcuts-custom--is-showing-more': isShowingMore,
		}"
	>
		<BaseH2
			v-if="title"
			class="w-3/12col pr-layout-gutter flex-shrink-0 hidden >=768:block"
			v-html="title"
		/>
		<ul
			v-if="shortcuts && shortcuts.length"
			ref="list"
			class="
				c-block-list-shortcuts-custom__list
				flex-grow
				grid
				gap-x-layout-gutter gap-y-sm
				grid-cols-1
				overflow-hidden
				>=768:pt-md
			"
			style="transition: max-height 250ms ease-in-out"
			@transitionend="() => setTransitioning(false)"
		>
			<li
				v-for="(shortcut, index) in shortcuts"
				:key="shortcut.key"
				:class="{
					'hidden >=768:block':
						!isShowingMore && index >= 3 && shortcuts.length > 4,
				}"
			>
				<NuxtLinkExt
					:to="shortcut.url"
					:target="shortcut.target"
					:class="[
						'flex items-start space-x-16 pr-8',
						'text-button font-medium font-darker-grotesque',
						'group',
					]"
				>
					<SvgArrow
						:class="['flex-shrink-0', 'mt-4 w-16 text-text']"
					/>

					<div
						:class="[
							'mb-4',
							'duration-500 ease-smooth-out',
							'transform group-hover:translate-x-8',
						]"
						v-html="shortcut.name"
					></div>
				</NuxtLinkExt>
			</li>
		</ul>

		<TransitionExt name="fade-in-out" duration="200">
			<BaseButton
				v-if="!isShowingMore && shortcuts && shortcuts.length > 4"
				class="absolute bottom-0 w-auto max-w-max >=768:hidden"
				light
				@click="showMore"
			>
				Vis flere

				<template #icon>
					<SvgIconCaret />
				</template>
			</BaseButton>
		</TransitionExt>
	</div>
</template>

<script>
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgIconCaret from '~/assets/svgs/icon-caret.svg?inline';

export default {
	name: 'BlockListShortcutsCustom',

	components: {
		SvgArrow,
		SvgIconCaret,
	},
	inheritAttrs: false,

	props: {
		title: {
			type: String,
			default: '',
		},
		shortcuts: {
			type: Array,
			default: () => [],
		},
	},

	data() {
		return {
			isShowingMore: false,
			isTransitioning: false,
		};
	},

	methods: {
		showMore() {
			const { list } = this.$refs;
			this.isShowingMore = true;

			if (list) {
				// 40px is added to counter the button's disappearance
				list.style.maxHeight = `${list.clientHeight}px`;
				this.$nextTick(() => {
					list.style.maxHeight = `${list.scrollHeight}px`;
				});
			}
		},

		setTransitioning(state = this.isTransitioning) {
			const { list } = this.$refs;
			if (list) {
				list.style.maxHeight = null;
			}
			this.isTransitioning = state;
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-shortcuts-custom {
	@apply relative flex flex-col >=768:flex-row pb-64 >=768:pb-0;
	transition: padding-bottom 250ms ease-in-out;
}
.c-block-list-shortcuts-custom--is-showing-more {
	@apply pb-0;
}

@screen >=768 {
	.c-block-list-shortcuts-custom__list {
		grid-template-columns: auto auto !important;
	}
}

@screen >=1024 {
	.c-block-list-shortcuts-custom__list {
		grid-template-columns: auto auto auto !important;
	}
}
</style>
