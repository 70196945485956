var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-block-list-shortcuts-custom",class:{
		'c-block-list-shortcuts-custom--is-showing-more': _vm.isShowingMore,
	}},[(_vm.title)?_c('BaseH2',{staticClass:"w-3/12col pr-layout-gutter flex-shrink-0 hidden >=768:block",domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),(_vm.shortcuts && _vm.shortcuts.length)?_c('ul',{ref:"list",staticClass:"c-block-list-shortcuts-custom__list flex-grow grid gap-x-layout-gutter gap-y-sm grid-cols-1 overflow-hidden >=768:pt-md",staticStyle:{"transition":"max-height 250ms ease-in-out"},on:{"transitionend":() => _vm.setTransitioning(false)}},_vm._l((_vm.shortcuts),function(shortcut,index){return _c('li',{key:shortcut.key,class:{
				'hidden >=768:block':
					!_vm.isShowingMore && index >= 3 && _vm.shortcuts.length > 4,
			}},[_c('NuxtLinkExt',{class:[
					'flex items-start space-x-16 pr-8',
					'text-button font-medium font-darker-grotesque',
					'group',
				],attrs:{"to":shortcut.url,"target":shortcut.target}},[_c('SvgArrow',{class:['flex-shrink-0', 'mt-4 w-16 text-text']}),_vm._v(" "),_c('div',{class:[
						'mb-4',
						'duration-500 ease-smooth-out',
						'transform group-hover:translate-x-8',
					],domProps:{"innerHTML":_vm._s(shortcut.name)}})],1)],1)}),0):_vm._e(),_vm._v(" "),_c('TransitionExt',{attrs:{"name":"fade-in-out","duration":"200"}},[(!_vm.isShowingMore && _vm.shortcuts && _vm.shortcuts.length > 4)?_c('BaseButton',{staticClass:"absolute bottom-0 w-auto max-w-max >=768:hidden",attrs:{"light":""},on:{"click":_vm.showMore},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgIconCaret')]},proxy:true}],null,false,1476878955)},[_vm._v("\n\t\t\tVis flere\n\n\t\t\t")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }